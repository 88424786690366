import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/discounts',
    name: 'discounts',
    component: () => import('@/pages/discounts/index.vue')
  },
  {
    path: '/books',
    name: 'books',
    component: () => import('@/pages/books/index.vue')
  },
  {
    path: '/books/:id',
    name: 'book-detail',
    component: () => import('@/pages/books/detail.vue')
  },
  {
    path: '/chapters/:id',
    name: 'chapter-detail',
    component: () => import('@/pages/chapters/detail.vue')
  },
  {
    path: '/chapters/:id/exercise',
    name: 'chapter-exercise',
    component: () => import('@/pages/chapters/exercise.vue')
  },
  {
    path: '/exercises',
    name: 'exercises',
    component: () => import('@/pages/exercises/index.vue')
  },
  {
    path: '/exercises/:id',
    name: 'exercise-detail',
    component: () => import('@/pages/exercises/detail.vue')
  },
  {
    path: '/exercises/new',
    name: 'new-exercise',
    component: () => import('@/pages/exercises/new.vue')
  },
  {
    path: '/competitions',
    name: 'competitions',
    component: () => import('@/pages/competitions/index.vue')
  },
  {
    path: '/competitions/:id',
    name: 'competition-detail',
    component: () => import('@/pages/competitions/detail.vue')
  },
  {
    path: '/competitions/:id/history',
    name: 'competition-battles-history',
    component: () => import('@/pages/battles/history.vue')
  },
  {
    path: '/battles/:id/new',
    name: 'new-battle',
    component: () => import('@/pages/battles/new.vue')
  },
  {
    path: '/battles/:id',
    name: 'battle-detail',
    component: () => import('@/pages/battles/detail.vue')
  },
  {
    path: '/rating',
    name: 'rating',
    component: () => import('@/pages/rating/index.vue')
  },
  {
    path: '/rating/history',
    name: 'rating-history',
    component: () => import('@/pages/rating/history.vue')
  },
  {
    path: '/results',
    name: 'results',
    component: () => import('@/pages/results/index.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/pages/settings/index.vue')
  },
  {
    path: '/settings/edit',
    name: 'settings-edit',
    component: () => import('@/pages/settings/edit.vue')
  },
  {
    path: '/settings/instructions',
    name: 'settings-instructions',
    component: () => import('@/pages/settings/instructions.vue')
  },
  {
    path: '/settings/faqs',
    name: 'settings-faqs',
    component: () => import('@/pages/settings/faqs.vue')
  },
  {
    path: '/pro/',
    name: 'pro',
    component: () => import('@/pages/pro/index.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
